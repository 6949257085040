@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@link-margin: 5px;

@shortcut-links-cls: ~'@{ra-prefix}-shortcut-links';
@shortcut-links-draggable-cls: ~'@{shortcut-links-cls}-draggable';
@shortcut-links-container-cls: ~'@{shortcut-links-cls}-container';

:export {
  shortcutLinksCls: @shortcut-links-cls;
  shortcutLinksDraggableCls: @shortcut-links-draggable-cls;
  shortcutLinksContainerCls: @shortcut-links-container-cls;
}

.@{shortcut-links-cls} {
  margin-top: 30px;
  margin-bottom: 30px;

  &.@{shortcut-links-draggable-cls} {
    @media only screen and (max-width: @ra-screen-md-max) {
      overflow: hidden;

      .@{shortcut-links-container-cls} {
        white-space: nowrap;
        // stylelint-disable declaration-block-no-redundant-longhand-properties
        overflow-x: auto;
        overflow-y: hidden;
        // stylelint-enable
      }
    }

    @media only screen and (min-width: @ra-screen-lg) {
      .@{shortcut-links-container-cls} {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
      }
    }
  }

  &:not(.@{shortcut-links-draggable-cls}) {
    @media only screen and (max-width: @ra-screen-md-max) {
      overflow: hidden;

      .@{shortcut-links-container-cls} {
        white-space: nowrap;
        // stylelint-disable declaration-block-no-redundant-longhand-properties
        overflow-x: auto;
        overflow-y: hidden;
        // stylelint-enable
      }
    }

    @media only screen and (min-width: @ra-screen-lg) {
      .@{shortcut-links-container-cls} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  .@{shortcut-links-container-cls} {
    margin: -@link-margin;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .ra-btn {
      margin: @link-margin;
      padding-left: 15px;
      padding-right: 15px;
      background-color: @ra-color-background;
      border: none;
      text-transform: none;
      font-weight: @ra-font-weight-light;

      &:hover {
        background: @ra-color-black;
      }
    }
  }
}

.ra-section.ra-section-color-gray {
  .@{shortcut-links-cls} {
    .ra-btn {
      background-color: @ra-color-white;
      
      &:hover {
        background-color: @ra-color-grayscale-50;
        color: @ra-color-white;
      }
    }
  }
}
